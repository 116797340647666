@tailwind base;
@tailwind components;
@tailwind utilities;

.form-wrapper {
    max-width: 400px;
}

.form-item label {
    @apply block mb-2 text-gray-400;
}

.form-item input {
    @apply w-full h-12 px-4 border rounded;
}

.button1 {
    @apply px-8 py-3 bg-indigo-600 hover:bg-indigo-500 transition-colors text-white rounded;
}

@keyframes spinner {
    to {transform: rotate(360deg);}
}
   
.spinner-wrapper {
    position: relative;
    width: 107px;
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 2px solid #07d;
    border-right: 2px solid transparent;
    animation: spinner .6s linear infinite;
}

@media screen and (max-width: 500px) {
    .page {
        @apply justify-start;
    }
}

@media screen and (max-height: 600px) {
    .page {
        @apply h-auto;
    }
}